// One Trust Form Event / Privacy Update
/* eslint-disable */

function getCookie(name) {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
}

const oneTrustForm = document.querySelector('.oneTrustForm');

if (oneTrustForm) {
  const formUrl = oneTrustForm.children[0].href;
  oneTrustForm.addEventListener('click', (event) => {
    event.preventDefault();
    const anonId = getCookie('tglr_anon_id');
    const { sourceKey } = window._Cohesion;
    const url = `${formUrl}?anonymous_id=${anonId}&sourceUid=${sourceKey}`;
    window.open(url);
  });
}
